import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import logo from "./assets/images/logo.png";

function App() {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(i18n?.language?.toUpperCase());

  const handleSelect = (code) => {
    i18n.changeLanguage(code?.toLowerCase());
    setSelected(code);
  };

  return (
    <>
      <ReactFlagsSelect
        selected={selected}
        onSelect={(code) => handleSelect(code)}
        countries={["AZ", "TR", "US", "RU"]}
        showSelectedLabel={true}
        customLabels={{
          AZ: { primary: "Azərbaycanca" },
          TR: { primary: "Türkçe" },
          US: { primary: "English" },
          RU: { primary: "Русский" },
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: 200,
            height: 200,
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <h1>{t("welcome.title")}</h1>
        <h2>{t("welcome.subtitle")}</h2>
        <p>{t("welcome.under_construction")}</p>
        <p>{t("welcome.thanks")}</p>
      </div>
    </>
  );
}

export default App;
